import { AsyncPipe, NgForOf } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

import { CxSidebarComponent, CxSidebarContainerComponent, CxSidebarModule } from '@bbraun/cortex/sidebar';
import { TranslocoDirective } from '@jsverse/transloco';

import { HelperService } from '../../../shared/services/utils/helper.service';
import { SidebarDisplayStore } from '../../../shared/signal-stores/sidebar-display-store/sidebar-display.store';
import { UserConfigsStoreFacade } from '../../../store/user-configs/user-configs-store.facade';

@Component({
  selector: 'app-country-switch-sidebar',
  standalone: true,
  imports: [
    CxSidebarModule,
    MatButtonModule,
    TranslocoDirective,
    MatIconModule,
    AsyncPipe,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule
  ],
  providers: [
    HelperService
  ],
  templateUrl: './country-switch-sidebar.component.html',
  styleUrl: './country-switch-sidebar.component.sass'
})
export class CountrySwitchSidebarComponent {
  @ViewChild('countrySwitchSidebar', { static: false, read: CxSidebarComponent })
  public countrySwitchSidebar: CxSidebarComponent | undefined;
  @ViewChild('countrySwitchSidebarContainer', { static: false, read: CxSidebarContainerComponent })
  public countrySwitchSidebarContainer: CxSidebarContainerComponent | undefined;

  public userConfigsFacade = inject(UserConfigsStoreFacade);
  public helper = inject(HelperService);
  public sidebarDisplayStore = inject(SidebarDisplayStore);

  public countryControl = new FormControl(
    this.userConfigsFacade.superAdminSelectedCountrySignal(),
    { validators: [ Validators.required ]}
  );

  private readonly window = window;

  public closeSidebar(): void {
    this.sidebarDisplayStore.hideCountrySwitchSidebar();
    this.countrySwitchSidebar?.close();
    this.countrySwitchSidebarContainer?.updateContentMargins();
  }

  public onSwitchCountryClicked(): void {
    if (this.countryControl.value) {
      localStorage.setItem('super-admin-country', JSON.stringify(this.countryControl.value));
      this.window.location.reload();
    }
  }
}
